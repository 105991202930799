<template>
  <div class="container mx-auto py-8 space-y-16">
    <div class="text-3xl font-bold">1 Click Zaps</div>

    <div class="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      <zap-card v-for="zap in zaps" :key="zap.id" :zap="zap"></zap-card>
    </div>
  </div>
</template>

<script>
  import ZapCard from '@/components/ZapCard.vue'
  import Zap from '@/domains/entities/Zap'

  export default {
    components: {
      ZapCard,
    },
    data() {
      const groups = Zap.query().reduce((a, b) => {
        const key = b.yieldBearingAsset
        if (!a[key]) {
          a[key] = b
        }
        return a
      }, {})

      return {
        zaps: Object.values(groups),
      }
    },
  }
</script>
