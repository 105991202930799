var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pp-card',[_c('pp-card-title',{staticClass:"text-lg font-medium"},[_vm._v(" Zap Farm "+_vm._s(_vm.zap.transactions[0].received[0].token.symbol)+" ")]),_c('pp-card-subtitle',{staticClass:"mt-2"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"text-sm"},[_vm._v("Expiry")]),_c('div',{staticClass:"ml-2 py-0.5 px-2 bg-p-green-100 text-p-green-700 rounded-md"},[_c('pp-formatted-date',{attrs:{"value":_vm.zap.expiryEpoch}})],1)])]),_c('hr'),_c('pp-card-body',{staticClass:"flex flex-grow p-0"},[_c('pp-stat',{attrs:{"header":"Total APR up to"},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.aprState.is('loading'))?_c('pp-skeleton-loader',{staticClass:"w-24 h-8"}):(_vm.aprState.is('resolved') || _vm.aprState.is('idle'))?_c('pp-formatted-percent',{staticClass:"text-3xl",attrs:{"value":100 * _vm.aprState.response.totalApr}}):(_vm.aprState.is('rejected'))?_c('div',[_c('pp-btn',{attrs:{"color":"p-red","variant":"accent"},on:{"click":_vm.fetchApr}},[_vm._v("Reload")])],1):_vm._e()]},proxy:true}])})],1),_c('pp-card-actions',{staticClass:"mt-4"},[_c('pp-btn',{staticClass:"flex",attrs:{"tag":"router-link","size":"lg","to":{
        name: 'ZapShow',
        params: {
          yieldBearingAsset: _vm.zap.yieldBearingAsset,
          action: _vm.zap.action,
          inputType: 'single',
        },
      }}},[_vm._v(" Zap ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }