<template>
  <pp-card>
    <pp-card-title class="text-lg font-medium">
      Zap Farm {{ zap.transactions[0].received[0].token.symbol }}
    </pp-card-title>

    <pp-card-subtitle class="mt-2">
      <div class="flex items-center">
        <div class="text-sm">Expiry</div>

        <div class="ml-2 py-0.5 px-2 bg-p-green-100 text-p-green-700 rounded-md">
          <pp-formatted-date :value="zap.expiryEpoch"></pp-formatted-date>
        </div>
      </div>
    </pp-card-subtitle>

    <hr />

    <pp-card-body class="flex flex-grow p-0">
      <pp-stat header="Total APR up to">
        <template v-slot:content>
          <pp-skeleton-loader v-if="aprState.is('loading')" class="w-24 h-8"></pp-skeleton-loader>
          <pp-formatted-percent
            v-else-if="aprState.is('resolved') || aprState.is('idle')"
            :value="100 * aprState.response.totalApr"
            class="text-3xl"
          ></pp-formatted-percent>
          <div v-else-if="aprState.is('rejected')">
            <pp-btn @click="fetchApr" color="p-red" variant="accent">Reload</pp-btn>
          </div>
        </template>
      </pp-stat>
    </pp-card-body>

    <pp-card-actions class="mt-4">
      <pp-btn
        class="flex"
        tag="router-link"
        size="lg"
        :to="{
          name: 'ZapShow',
          params: {
            yieldBearingAsset: zap.yieldBearingAsset,
            action: zap.action,
            inputType: 'single',
          },
        }"
      >
        Zap
      </pp-btn>
    </pp-card-actions>
  </pp-card>
</template>

<script>
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'

  export default {
    props: {
      zap: { type: Object, required: true },
    },
    data() {
      return {
        aprState: createState({
          response: {
            totalApr: '0.0',
          },
        }),
      }
    },
    methods: {
      fetchApr() {
        new PromiseHandler(
          () => this.zap.contract(this.$store.getters['wallet/identity']).apr(),
          this.aprState
        ).execute()
      },
    },
    created() {
      this.fetchApr()
    },
  }
</script>
